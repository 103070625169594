import React, { useState, useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
/** @jsx jsx */
import { jsx, Flex, Box, Text } from "theme-ui"
import { FaList } from "react-icons/fa"
import { GoEyeClosed } from "react-icons/go"
import { useAppContext } from "../../context/AppContext"
import scrollTo from "gatsby-plugin-smoothscroll"

// Toggle button sizes
const width = "20px"
const height = "20px"

const centerLeft = {
  padding: 2,
  position: "fixed",
  left: 0,
}
const toc = {
  zIndex: "100",
  cursor: "pointer",
  top: "50%",
}
const drawerWidth = 300
const tocDrawer = {
  padding: 0,
  width: drawerWidth,
  height: 0,
  backgroundColor: "background",
  // border: "1px solid red",
  zIndex: "99",
  cursor: "default",
  overflow: "hidden",
}
const tocItem = {
  cursor: "pointer",
  "&:hover": {
    border: "1px dashed",
    borderColor: "muted",
  },
}
// animation mode styles
const drawerStyles = {
  visible: {
    width: drawerWidth,
    height: "100%",
    top: "0%",
  },
  hidden: {
    width: 0,
    height: "0%",
    top: "50%",
    transition: {
      delay: 1.2,
    },
  },
}

const showDrawerBtnStyles = {
  visible: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0,
    },
  },
  hidden: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 1,
    },
  },
}
const hideDrawerBtnStyles = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}

const tocItemsModes = {
  visible: {
    opacity: 1,
    x: 0,
  },
  hidden: {
    opacity: 0,
    x: -20,
  },
}
const TableOfContentsLarge = ({ data, render }) => {
  const { showToc, size } = useAppContext()
  const renderToc = render & (size.headerSize === "large")
  const controls = useAnimation()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    controls.start(isVisible ? "visible" : "hidden")
  }, [isVisible, controls])

  return (
    <>
      {renderToc ? (
        <motion.div
          animate={{ opacity: showToc ? 1 : 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Flex
            onClick={() => {
              setIsVisible(!isVisible)
            }}
            sx={{ ...centerLeft, ...toc }}
          >
            <motion.div
              sx={{ position: "absolute" }}
              animate={controls}
              variants={hideDrawerBtnStyles}
              transition={{ type: "spring", damping: 60, stiffness: 180 }}
            >
              <Box>
                <GoEyeClosed
                  sx={{
                    width,
                    height,
                    color: "text",
                    opacity: isVisible ? 1 : 0,
                  }}
                />
              </Box>
            </motion.div>

            <motion.div
              sx={{ position: "absolute" }}
              animate={controls}
              variants={showDrawerBtnStyles}
              transition={{ type: "spring", damping: 60, stiffness: 180 }}
            >
              <Flex>
                <FaList sx={{ width, height, color: "text" }} />
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: "bold",
                    position: "relative",
                    top: "2px",
                  }}
                  pl={1}
                >
                  ToC
                </Text>
              </Flex>
            </motion.div>
          </Flex>
          <motion.div
            id="lookatme"
            sx={{ ...centerLeft, ...toc, ...tocDrawer }}
            animate={controls}
            variants={drawerStyles}
            transition={{ type: "spring", damping: 60, stiffness: 280 }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                paddingLeft: 36,
              }}
            >
              <Box sx={{ alignSelf: "center" }}>
                {data.map((h1, i) => {
                  const items = h1.items
                  return (
                    <div key={i}>
                      <motion.div
                        animate={controls}
                        variants={tocItemsModes}
                        transition={{ duration: 0.6, delay: 0.3 }}
                      >
                        <Box
                          sx={{ ...{ fontSize: 3 }, ...tocItem }}
                          key={i}
                          ml={1}
                          p={2}
                          onClick={() => scrollTo(h1.url)}
                        >
                          {h1.title}
                        </Box>
                      </motion.div>
                      {items
                        ? items.map((h2, j) => {
                            return (
                              <motion.div
                                key={j}
                                animate={controls}
                                variants={tocItemsModes}
                                transition={{ duration: 0.6, delay: 0.6 }}
                              >
                                <Box
                                  sx={tocItem}
                                  ml={3}
                                  p={1}
                                  onClick={() => scrollTo(h2.url)}
                                >
                                  {h2.title}
                                </Box>
                              </motion.div>
                            )
                          })
                        : null}
                    </div>
                  )
                })}
              </Box>
            </Flex>
          </motion.div>
        </motion.div>
      ) : null}
    </>
  )
}
export default TableOfContentsLarge
