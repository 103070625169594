import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
/** @jsx jsx */
import { jsx, Box, Flex, Text, Container, Link } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Image from "gatsby-image"
import { GiMusicalNotes } from "react-icons/gi"
import { BsCalendar } from "react-icons/bs"
import { MdUpdate } from "react-icons/md"
import { AiOutlineRead } from "react-icons/ai"
import Blockquote from "../components/Blockquote"
import TableOfContentsLarge from "../components/TableOfContents/TableOfContentsLarge"
// import "prismjs/themes/prism-solarizedlight.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "./syntaxHighlight.css"

const alignSelfCenter = {
  alignSelf: "center",
}
const italicFont = {
  fontStyle: "italic",
}
const introIconWrapper = {
  alignSelf: "center",
  width: ["20px", "25px"],
  height: ["20px", "25px"],
}
const introIcons = { width: "100%", height: "100%" }
const introText = {
  fontSize: [1, 1, 2],
}

const shortCodes = { Blockquote }

const PostMainTemplate = ({ data }) => {
  const {
    title,
    // category,
    creationDate,
    lastUpdate,
    featuredImage,
    mainContent: { childMdx },
    postMusic,
    tag,
  } = data.post
  // const context = useAppContext()
  const { contentMd, timeToRead, tableOfContents } = childMdx

  // Need this because Post Music is a one-to-many reference and take only first entry for this template
  const post_music = postMusic[0]
  const renderToc = (timeToRead > 3) & (tableOfContents.items !== undefined)

  return (
    <Layout data={{ tableOfContents, renderToc }}>
      <Container sx={{ maxWidth: "1200px" }} p={0}>
        <Image
          fluid={featuredImage.fluid}
          sx={{ width: "100%", height: "450px" }}
        ></Image>
      </Container>
      <TableOfContentsLarge render={renderToc} data={tableOfContents.items} />

      <Container>
        <Flex sx={{ flexDirection: "column" }}>
          <Box py={3} sx={alignSelfCenter}>
            <Text variant="displayTitle" sx={{ textAlign: "center" }}>
              {title}
            </Text>
            <Flex sx={{ justifyContent: "center" }} mt={3}>
              <Text
                variant="mutedSubtitle"
                sx={{ ...{ textAlign: "center" }, ...italicFont }}
                mx={2}
              >
                <Flex>
                  <Box sx={introIconWrapper} mr={2}>
                    <BsCalendar sx={introIcons} />
                  </Box>
                  <Text sx={introText} mr={3}>
                    {creationDate}
                  </Text>
                  <Box sx={introIconWrapper} mr={2}>
                    <MdUpdate sx={introIcons} />
                  </Box>
                  <Text sx={introText} mr={3}>
                    {lastUpdate}
                  </Text>
                  <Box sx={introIconWrapper} mr={2}>
                    <AiOutlineRead sx={introIcons} />
                  </Box>
                  <Text sx={introText}>{timeToRead} Min</Text>
                </Flex>
              </Text>
            </Flex>
            <Flex sx={{ justifyContent: "center" }} my={2}>
              <GiMusicalNotes sx={{ ...alignSelfCenter, ...{ mx: 2 } }} />
              <Link
                href={post_music.url}
                target="_blank"
                sx={{ ...alignSelfCenter, ...{ mx: 2 } }}
              >
                {post_music.title}
              </Link>
            </Flex>
          </Box>
          <MDXProvider
            components={{
              ...shortCodes,
            }}
          >
            <MDXRenderer>{contentMd}</MDXRenderer>
          </MDXProvider>
          <Flex sx={{ my: 5 }}>
            <Text
              pr={4}
              pl={1}
              sx={{
                fontSize: 2,
                fontWeight: "bold",
                color: "muted",
                // custom
                fontStyle: "italic",
                textTransform: "uppercase",
                alignSelf: "center",
              }}
            >
              Tags
            </Text>

            <Flex
              sx={{
                fontFamily: "monospace",
                flexWrap: "wrap",
              }}
            >
              {tag.map(({ name }) => {
                return (
                  <Box
                    key={name}
                    p={1}
                    sx={{
                      color: "primary",
                      fontWeight: "tags",
                      fontSize: 2,
                      textTransform: "uppercase",
                    }}
                  >
                    {name}
                  </Box>
                )
              })}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SinglePostQuery($slug: String!) {
    post: contentfulPost(slug: { eq: $slug }) {
      title
      category {
        name
      }
      creationDate(formatString: "MMMM D YYYY")
      lastUpdate(formatString: "MMMM D YYYY")
      featuredImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      mainContent: mainPost {
        childMdx {
          contentMd: body
          timeToRead
          tableOfContents
        }
      }
      postMusic {
        composer
        title
        url
        notes {
          notes
        }
      }
      tag {
        name
      }
    }
  }
`
export default PostMainTemplate
