import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { IoMdQuote } from "react-icons/io"

const blockquote = {
  borderLeft: "3px solid #00cc8f",
  color: "text",
  fontFamily: `Georgia, Times, "Times New Roman", serif`,
  fontSize: "1.25em",
  fontStyle: "italic",
  lineHeight: "1.8em",
  padding: "1em 2em",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
}
const quoteIcon = {
  position: "absolute",
  left: "-16px",
  backgroundColor: "background",
  padding: 1,
  width: "30px",
  height: "30px",
}

const citeStyle = {
  display: "block",
  fontSize: 1,
  marginTop: 2,
}

const Blockquote = ({ data }) => {
  const { text, cite } = data

  return (
    <blockquote sx={blockquote}>
      <IoMdQuote sx={quoteIcon} />
      {text}
      <cite sx={citeStyle}>- {cite}</cite>
    </blockquote>
  )
}

export default Blockquote
